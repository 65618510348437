import { useParams } from 'react-router-dom'
import DefaultLayout from '../../layout/DefaultLayout.tsx'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb.tsx'
import { useRestClient } from '../../useRestClient.ts'
import { useGetPosition } from '../../http/hooks.ts'
import Loader from '../../common/Loader'
import { IQuestion } from '../../services/nextpeakClient'
import Card from '../../components/Card.tsx'
import { fromCamelCase, toCamelCase, toCapitalized } from '../Home/utils.ts'

const groupByProperties = (questions: IQuestion[]) => {
	return questions.reduce(
		(acc, question) => {
			const { name, subName } = question

			const lowerCaseName = toCamelCase(name.toLowerCase())
			const lowerCaseSubName = toCamelCase(subName.toLowerCase())

			// If the `name` group does not exist, create it
			if (!acc[lowerCaseName]) {
				acc[lowerCaseName] = {}
			}

			// If the `subName` group under `name` does not exist, create it
			if (!acc[lowerCaseName][lowerCaseSubName]) {
				acc[lowerCaseName][lowerCaseSubName] = []
			}

			// Push the question into the appropriate group
			acc[lowerCaseName][lowerCaseSubName].push(question)

			return acc
		},
		{} as Record<string, Record<string, IQuestion[]>>
	)
}

interface QuestionsProps {
	questions: IQuestion[]
}

const Questions = ({ questions }: QuestionsProps) => {
	const groupedQuestions = groupByProperties(questions)

	return (
		<>
			{Object.entries(groupedQuestions).map(([name, subQuestions]) => (
				<Card key={name}>
					<h3 className="text-left text-title-xsm font-bold text-black dark:text-white sm:text-title-md mt-3">{toCapitalized(name)}</h3>
					<hr className="my-10 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10" />
					{Object.entries(subQuestions).map(([subName, questions]) => (
						<div key={subName} className="px-5 pt-4 sm:px-7.5">
							<h4 className="text-left text-title-xsm font-bold text-black dark:text-white sm:text-title-sm mb-4">
								{toCapitalized(fromCamelCase(subName))}
							</h4>
							<ul>
								{questions.map((question) => (
									<li key={question.id} className="mb-4">
										{question.subordinateText}
									</li>
								))}
							</ul>
							<hr className="my-10 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10" />
						</div>
					))}
				</Card>
			))}
		</>
	)
}

const PositionDetail = () => {
	const { positionId } = useParams()
	const restClient = useRestClient()
	const { data, isLoading } = useGetPosition(restClient, {
		positionId: positionId!,
		includes: ['assessmentType', 'assessmentType.questions'],
	})

	return (
		<DefaultLayout>
			{isLoading && <Loader />}
			{!isLoading && data && (
				<>
					<Breadcrumb segments={[{ path: '/positions', name: 'Positions' }]} pageName={data.title} />
					<div className="flex flex-col">
						<p className="mb-7">{data.description}</p>
						<Questions questions={data.assessmentType?.questions!} />
					</div>
				</>
			)}
		</DefaultLayout>
	)
}

export default PositionDetail
