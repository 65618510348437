import React, { useCallback, useState, useEffect } from 'react'
import Card from '../../components/Card.tsx'
import { useRestClient } from '../../useRestClient.ts'
import { useGetOrganizationInvite, useGetOrganizationPublic, useUpdateOrgInvite } from '../../http/hooks.ts'
import FullPageLoader from '../../common/FullPageLoader'
import { useParams, useSearchParams } from 'react-router-dom'
import { InviteAction } from '../../services/nextpeakClient'

const ConfirmInvite: React.FC = () => {
	const { inviteId } = useParams()
	const [searchParams] = useSearchParams()

	const invitationId = searchParams.get('invitationId')
	const userId = searchParams.get('userId')

	if (!inviteId || !invitationId) {
		return <div>Invalid invite</div>
	}

	const restClient = useRestClient({ withoutToken: true })
	const [firstName, setFirstName] = useState<string>('')
	const [lastName, setLastName] = useState<string>('')
	const [password, setPassword] = useState<string>('')

	const { data, isSuccess, isLoading: isLoadingInvite } = useGetOrganizationInvite(restClient, { inviteId })
	const { data: orgData, isLoading: isLoadingOrg } = useGetOrganizationPublic(
		restClient,
		{ organizationId: data!.organizationId! },
		{
			enabled: !!data?.organizationId,
		}
	)

	useEffect(() => {
		if (data?.inviteStatus === 'Accepted') {
			window.location.href = '/'
		}
	}, [data])

	const mutation = useUpdateOrgInvite(restClient)

	const handleSubmit = useCallback(() => {
		try {
			mutation.mutate({
				inviteId,
				inviteData: {
					action: InviteAction.ACCEPT,
					invitationId,
					userId: userId || undefined,
					firstName,
					lastName,
					password,
				},
			}) // TODO: redirect on success
		} catch (e) {
			console.error(e)
		}
	}, [mutation])

	const isLoading = isLoadingInvite || isLoadingOrg

	if (isLoading) {
		return <FullPageLoader />
	}

	if (!isLoading && !isSuccess) {
		return (
			<div className="w-[100%] min-h-dvh p-7 flex justify-center items-center">
				<div className="max-w-100">
					<Card>
						<h2 className="text-center mb-9 text-2xl font-bold text-black dark:text-white">Invite not found</h2>
					</Card>
				</div>
			</div>
		)
	}

	return (
		<div className="w-[100%] min-h-dvh p-7 flex justify-center items-center">
			<div className="max-w-150">
				<Card>
					<h2 className="text-center mb-9 text-2xl font-bold text-black dark:text-white">Accept invitation to {orgData!.name}</h2>
					{!userId && (
						<div>
							<div className="flex">
								<input
									type="text"
									className="w-full border border-stroke rounded py-3.5 px-4 focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input flex-1 mr-2"
									placeholder="First name"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
								<input
									type="text"
									className="w-full border border-stroke rounded py-3.5 px-4 focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input flex-1 ml-2"
									placeholder="Last name"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
							<input
								type="password"
								className="w-full border border-stroke rounded py-3.5 px-4 mt-2 focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					)}
					<button
						onClick={handleSubmit}
						className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 mt-4 font-medium text-white transition hover:bg-opacity-90"
					>
						Join
					</button>
				</Card>
			</div>
		</div>
	)
}

export default ConfirmInvite
