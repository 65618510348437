import DefaultLayout from '../../layout/DefaultLayout.tsx'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb.tsx'
import Loader from '../../common/Loader'
import React, { useCallback, useRef, useState } from 'react'
import Card from '../../components/Card.tsx'
import { useNavigate } from 'react-router-dom'
import { useRestClient } from '../../useRestClient.ts'
import { useGetPosition, useGetPositions } from '../../http/hooks.ts'
import { IPosition } from '../../services/nextpeakClient'

const transformPositionsArray = (positions: IPosition[]) => {
	// Step 1: Create a map to accumulate levels for each title
	const titleMap = new Map()

	// Step 2: Populate the map with levels for each title
	positions.forEach(({ level, role, id }) => {
		if (!titleMap.has(role)) {
			titleMap.set(role, [])
		}
		titleMap.get(role).push({ id, level })
	})

	// Step 3: Convert the map into the desired output format
	return Array.from(titleMap, ([role, levels]) => ({
		role,
		levels,
	}))
}

const roleLevelsSorter = (a: { level: string }, b: { level: string }) => {
	const numA = parseInt(a.level.replace('IC', ''))
	const numB = parseInt(b.level.replace('IC', ''))

	return numA - numB
}

interface PositionDetailProps {
	id: string
	isActive: boolean
}

const PositionDetail = ({ id, isActive }: PositionDetailProps) => {
	const restClient = useRestClient()
	const { data, isLoading } = useGetPosition(
		restClient,
		{ positionId: id, includes: ['positionSections', 'positionSections.items'] },
		{ enabled: isActive }
	)

	return (
		<div className="flex flex-col gap-4">
			{isLoading && <Loader />}
			{!isLoading && data && (
				<div className="text-gray-600 mt-4" style={{ whiteSpace: 'pre-line' }}>
					{data?.positionSections?.map((item, index) => (
						<React.Fragment key={index}>
							<strong>
								{item.header
									.split('_') // Split the string into an array at each underscore
									.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
									.join(' ') + ' '}
							</strong>
							<br />
							<br />
							<ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
								{item.items?.map((field, index) => (
									<li key={index}>
										<p>{field?.text}</p>
										<br />
									</li>
								))}
							</ul>
						</React.Fragment>
					))}
				</div>
			)}
			{!isLoading && !data && <p className="text-center">No data found</p>}
		</div>
	)
}

interface PositionAccordionProps {
	role: string
	position: { id: string; level: string }
	active: number | null
	handleToggle: (index: number) => void
	id: number
}

const PositionAccordion = ({ position, active, id, handleToggle }: PositionAccordionProps) => {
	const contentEl = useRef<HTMLDivElement>(null)
	const navigate = useNavigate()

	const isActive = active === id

	const handleLinkClick = useCallback((e: any) => {
		e.preventDefault()
		e.stopPropagation()
		navigate(`/positions/${position.id}`)
	}, [])

	return (
		<div className="rounded-md border border-stroke p-4 shadow-9 dark:border-strokedark dark:shadow-none sm:p-6">
			<button
				className={`flex w-full items-center gap-1.5 sm:gap-3 xl:gap-6 ${isActive ? 'active' : ''}`}
				onClick={() => handleToggle(Number(id))}
			>
				<div className="flex h-10.5 w-full max-w-10.5 items-center justify-center rounded-md bg-[#F3F5FC] dark:bg-meta-4">
					<svg
						className={`fill-primary stroke-primary duration-200 ease-in-out dark:fill-white dark:stroke-white ${
							isActive ? 'rotate-180' : ''
						}`}
						width="18"
						height="10"
						viewBox="0 0 18 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.28882 8.43257L8.28874 8.43265L8.29692 8.43985C8.62771 8.73124 9.02659 8.86001 9.41667 8.86001C9.83287 8.86001 10.2257 8.69083 10.5364 8.41713L10.5365 8.41721L10.5438 8.41052L16.765 2.70784L16.771 2.70231L16.7769 2.69659C17.1001 2.38028 17.2005 1.80579 16.8001 1.41393C16.4822 1.1028 15.9186 1.00854 15.5268 1.38489L9.41667 7.00806L3.3019 1.38063L3.29346 1.37286L3.28467 1.36548C2.93287 1.07036 2.38665 1.06804 2.03324 1.41393L2.0195 1.42738L2.00683 1.44184C1.69882 1.79355 1.69773 2.34549 2.05646 2.69659L2.06195 2.70196L2.0676 2.70717L8.28882 8.43257Z"
							fill=""
							stroke=""
						/>
					</svg>
				</div>

				<div>
					<h4 className="text-left text-title-xsm font-medium text-black dark:text-white">{position.level}</h4>
				</div>

				<a onClick={handleLinkClick} className="rounded-2xl font-medium bg-blue-400 inline-block py-1 px-4 text-white ml-auto mr-0">
					See questions
				</a>
			</button>

			<div ref={contentEl} className={`mt-5 ml-16.5 duration-200 ease-in-out ${isActive ? 'block' : 'hidden'}`}>
				<PositionDetail id={position.id} isActive={isActive} />
			</div>
		</div>
	)
}

interface RoleDetailProps {
	position: { role: string; levels: { id: string; level: string }[] }
	isActive: boolean
}

const RoleDetail = ({ position }: RoleDetailProps) => {
	const [active, setActive] = useState<number | null>(null)

	const handleToggle = (index: number) => {
		if (active === index) {
			setActive(null)
		} else {
			setActive(index)
		}
	}

	return (
		<div className="flex flex-col gap-4">
			{position.levels.sort(roleLevelsSorter).map((level, index) => (
				<PositionAccordion role={position.role} position={level} key={index} id={index} active={active} handleToggle={handleToggle} />
			))}
		</div>
	)
}

interface RoleAccordionProps {
	position: { role: string; levels: { id: string; level: string }[] }
	active: number | null
	handleToggle: (index: number) => void
	id: number
}

const RoleAccordion = ({ position, active, id, handleToggle }: RoleAccordionProps) => {
	const contentEl = useRef<HTMLDivElement>(null)

	const isActive = active === id

	return (
		<Card>
			<button className={`flex w-full items-center justify-between gap-2 ${isActive ? 'active' : ''}`} onClick={() => handleToggle(id)}>
				<div>
					<h4 className="text-left text-title-xsm font-bold text-black dark:text-white sm:text-title-md">{position.role}</h4>
				</div>
				<div className="flex h-9 w-full max-w-9 items-center justify-center rounded-full border border-primary dark:border-white">
					<svg
						className={`fill-primary duration-200 ease-in-out dark:fill-white ${isActive ? 'hidden' : ''}`}
						width="15"
						height="15"
						viewBox="0 0 15 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.2969 6.51563H8.48438V1.70312C8.48438 1.15625 8.04688 0.773438 7.5 0.773438C6.95313 0.773438 6.57031 1.21094 6.57031 1.75781V6.57031H1.75781C1.21094 6.57031 0.828125 7.00781 0.828125 7.55469C0.828125 8.10156 1.26563 8.48438 1.8125 8.48438H6.625V13.2969C6.625 13.8438 7.0625 14.2266 7.60938 14.2266C8.15625 14.2266 8.53906 13.7891 8.53906 13.2422V8.42969H13.3516C13.8984 8.42969 14.2813 7.99219 14.2813 7.44531C14.2266 6.95312 13.7891 6.51563 13.2969 6.51563Z"
							fill=""
						/>
					</svg>

					<svg
						className={`fill-primary duration-200 ease-in-out dark:fill-white ${isActive ? 'block' : 'hidden'}`}
						width="15"
						height="3"
						viewBox="0 0 15 3"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.503 0.447144C13.446 0.447144 13.503 0.447144 13.503 0.447144H1.49482C0.925718 0.447144 0.527344 0.902427 0.527344 1.47153C0.527344 2.04064 0.982629 2.43901 1.55173 2.43901H13.5599C14.129 2.43901 14.5273 1.98373 14.5273 1.41462C14.4704 0.902427 14.0151 0.447144 13.503 0.447144Z"
							fill=""
						/>
					</svg>
				</div>
			</button>

			<div ref={contentEl} className={`mt-5 duration-200 ease-in-out ${isActive ? 'block' : 'hidden'}`}>
				<RoleDetail position={position} isActive={isActive} />
			</div>
		</Card>
	)
}

interface PositionsAccordionContainerProps {
	positions: IPosition[]
}

const PositionsAccordionContainer = ({ positions }: PositionsAccordionContainerProps) => {
	const [active, setActive] = useState<number | null>(null)

	const handleToggle = (index: number) => {
		if (active === index) {
			setActive(null)
		} else {
			setActive(index)
		}
	}

	const positionsAndLevelsArray = transformPositionsArray(positions)

	return (
		<div className="flex flex-col gap-4">
			{positionsAndLevelsArray.map((position, index) => (
				<RoleAccordion position={position} key={index} id={index} active={active} handleToggle={handleToggle} />
			))}
		</div>
	)
}

const Positions = () => {
	const restClient = useRestClient()

	const { data, isLoading } = useGetPositions(restClient)

	return (
		<DefaultLayout>
			<Breadcrumb segments={[{ path: '/positions', name: 'Positions' }]} pageName="Overview" />
			{isLoading && <Loader />}
			{data && <PositionsAccordionContainer positions={data} />}
		</DefaultLayout>
	)
}

export default Positions
