import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { Link, useParams } from 'react-router-dom'
import Loader from '../../common/Loader'
import { AssessmentsTable } from '../Assessments'
import { useCallback, useState } from 'react'
import { getMe } from '../../MeProvider.ts'
import { useOrgAuth } from '../../OrgAuthProvider.ts'
import { useRestClient } from '../../useRestClient.ts'
import {
	useCreateAssessment,
	useGetAssessments,
	useGetFullTrajectory,
	useGetOrganizationUsers,
	useGetUserOrganizationRolesV2,
	useGetUserPositionAssessments,
	useGetUserV2,
} from '../../http/hooks.ts'
import { toast } from 'react-toastify'
import { EmployeesTable } from './index.tsx'
import { useModal } from '../../components/modals/useModal.ts'
import { useQueryClient } from '@tanstack/react-query'
import Card from '../../components/Card.tsx'
import { IPositionTrajectory, IUser } from '../../services/nextpeakClient'
import { LatestAssessment, PositionAssessments } from '../Home'

const toSnakeCase = (str: string): string => {
	const words = str.split(' ')

	return words.map((word) => word.toLowerCase()).join('_')
}

interface ITrajectoryWithCompletion extends IPositionTrajectory {
	isCompleted: boolean
}

export const markCompleted = (items: IPositionTrajectory[], currentPositionId: string): ITrajectoryWithCompletion[] => {
	// Step 1: Sort the array by 'progressionOrder'
	items.sort((a, b) => a.progressionOrder! - b.progressionOrder!)

	const mapped: ITrajectoryWithCompletion[] = items.map((item) => ({ ...item, isCompleted: false }))

	// Step 2: Add 'isCompleted' property based on 'currentPositionId'
	let isCompleted = true
	for (let item of mapped) {
		if (isCompleted) {
			item.isCompleted = true
		} else {
			item.isCompleted = false
		}

		// If current item matches 'currentPositionId', set 'isCompleted' to false for remaining items
		if (item.fromPosition!.id === currentPositionId) {
			isCompleted = false
		}
	}

	return mapped
}

export const CareerPath = ({ employee, trajectoryItems }: { employee: IUser; trajectoryItems: IPositionTrajectory[] }) => {
	const trajectoryName = trajectoryItems[0].path

	const markedItems = markCompleted(trajectoryItems, employee.userPosition!.positionTrajectory!.fromPosition!.id)

	return (
		<>
			<h3 className="text-2xl mb-6 mt-4 font-semibold text-black text-center">{trajectoryName}</h3>
			<Card>
				<div className="flex gap-3">
					{markedItems.map((trajectory) => (
						<div
							key={trajectory.id}
							className={`inline-block flex-grow text-center font-bold rounded-md ${trajectory.isCompleted ? 'bg-meta-3 text-white' : ''}`}
						>
							{trajectory.fromPosition!.level}
						</div>
					))}
				</div>
			</Card>
		</>
	)
}

const CareerPathView = ({ employee }: { employee: IUser }) => {
	const restClient = useRestClient()
	const { data, isLoading } = useGetFullTrajectory(restClient, {
		path: toSnakeCase(employee.userPosition!.positionTrajectory!.fromPosition!.role) + '_path',
	})
	const { data: assessmentData, isLoading: isLoadingAssessments } = useGetUserPositionAssessments(restClient, {
		userId: employee.id!,
		filters: {
			latest: 0,
			isCompleted: 1,
			includes: ['assessmentResponses', 'assessmentResponses.answers'],
		},
	})

	return (
		<div>
			{isLoading && <Loader />}
			{!isLoading && data && <CareerPath employee={employee} trajectoryItems={data} />}
			{isLoadingAssessments && <Loader />}
			<div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
				<PositionAssessments data={assessmentData} isLoading={isLoadingAssessments} />
				<LatestAssessment data={assessmentData} isLoading={isLoadingAssessments} />
			</div>
		</div>
	)
}

const SubordinatesTableView = ({ employeeId }: { employeeId: string }) => {
	const {
		authOrgUser: { orgId },
	} = useOrgAuth()
	const me = getMe()
	const { onOpen } = useModal('InviteUserModal')
	const restClient = useRestClient()
	const { data: employees } = useGetOrganizationUsers(restClient, { organizationId: orgId, managerId: employeeId }, { enabled: !!orgId })

	const iamManager = me.organizationRoles.includes('manager')
	const iamAdmin = me.organizationRoles.includes('admin')

	const iCanInvite = iamManager || iamAdmin

	return (
		<>
			{iCanInvite && (
				<div className="flex justify-end">
					<button
						onClick={() => onOpen({ managerId: employeeId })}
						className="inline-flex items-center justify-center rounded-md bg-meta-3 px-6 py-2 text-center font-medium text-white hover:bg-opacity-90"
					>
						Invite subordinate
					</button>
				</div>
			)}
			{employees && <EmployeesTable employees={employees} />}
		</>
	)
}

const AssessmentsTableView = ({ employeeId, employeeManagerId }: { employeeId: string; employeeManagerId?: string }) => {
	const queryClient = useQueryClient()
	const {
		authOrgUser: { orgId },
	} = useOrgAuth()
	const me = getMe()

	const isAuthUserEmployeeManager = me.id === employeeManagerId
	const isAuthUserEmployee = me.id === employeeId

	const restClient = useRestClient()
	const {
		data: assessments,
		isLoading,
		isSuccess,
	} = useGetAssessments(
		restClient,
		{
			employeeUserId: employeeId,
			includes: ['assessmentResponses', 'assessmentType', 'assessmentType.position', 'employee'],
		},
		{ enabled: isAuthUserEmployeeManager || isAuthUserEmployee }
	)

	const mutation = useCreateAssessment(restClient)

	const handleSubmit = useCallback(async () => {
		try {
			if (!employeeManagerId) {
				toast.error('Employee does not have a manager')
				return
			}
			if (!isAuthUserEmployeeManager) {
				toast.error('You are not allowed to create assessments for this user')
				return
			}
			const data = { employeeUserId: employeeId, managerUserId: employeeManagerId, organizationId: orgId }
			await mutation.mutateAsync(data)

			toast.success('Assessment created')
			// @ts-ignore
			queryClient.invalidateQueries(['UserAssessments', { userId: employeeId, userIsManager: false }])
		} catch (e) {
			console.error(e)
			toast.error('Failed to create assessment')
		}
	}, [mutation])

	if (isLoading && !isSuccess) {
		return <Loader />
	}

	return (
		<>
			{isAuthUserEmployeeManager && (
				<div className="flex justify-end">
					<button
						onClick={handleSubmit}
						className="inline-flex items-center justify-center rounded-md bg-meta-3 px-6 py-2 text-center font-medium text-white hover:bg-opacity-90"
					>
						Create assessment
					</button>
				</div>
			)}
			{(isAuthUserEmployeeManager || isAuthUserEmployee) && <AssessmentsTable assessments={assessments ?? []} />}
			{!(isAuthUserEmployeeManager || isAuthUserEmployee) && <div>You are not allowed to see anything here</div>}
		</>
	)
}

const TabbedTableView = ({
	employeeId,
	employeeManagerId,
	employee,
	roles,
}: {
	employeeId: string
	employeeManagerId?: string
	roles: { name: string; id: string }[]
	employee: any
}) => {
	const [openTab, setOpenTab] = useState(1)

	const activeClasses = 'text-primary border-primary'
	const inactiveClasses = 'border-transparent'

	const isUserManager = roles.some((role) => role.name === 'manager')

	return (
		<div>
			<div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
				<Link
					to="#"
					className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
						openTab === 1 ? activeClasses : inactiveClasses
					}`}
					onClick={() => setOpenTab(1)}
				>
					Assessments
				</Link>
				{isUserManager && (
					<Link
						to="#"
						className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
							openTab === 2 ? activeClasses : inactiveClasses
						}`}
						onClick={() => setOpenTab(2)}
					>
						Subordinates
					</Link>
				)}
				<Link
					to="#"
					className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
						openTab === 3 ? activeClasses : inactiveClasses
					}`}
					onClick={() => setOpenTab(3)}
				>
					Career path
				</Link>
			</div>
			<div>
				<div className={`${openTab === 1 ? 'block' : 'hidden'}`}>
					<AssessmentsTableView employeeId={employeeId} employeeManagerId={employeeManagerId} />
				</div>
				{isUserManager && (
					<div className={`${openTab === 2 ? 'block' : 'hidden'}`}>
						<SubordinatesTableView employeeId={employeeId} />
					</div>
				)}
				<div className={`${openTab === 3 ? 'block' : 'hidden'}`}>
					<CareerPathView employee={employee} />
				</div>
			</div>
		</div>
	)
}

const EmployeeDetail = () => {
	const { onOpen: openEditRoles } = useModal('EditRolesModal')
	const { onOpen: openPromote } = useModal('PromoteEmployeeModal')
	const { onOpen: openChangeManager } = useModal('ChangeManagerModal')
	const { employeeId } = useParams()
	const me = getMe()

	const isAuthUserAdmin = me.organizationRoles.includes('admin')

	if (!employeeId || employeeId === 'undefined') {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>How is this even possible</div>
				</div>
			</DefaultLayout>
		)
	}

	const restClient = useRestClient()
	const { data: employee, isLoading } = useGetUserV2(restClient, {
		userId: employeeId,
		includes: [
			'organization',
			'userPosition',
			'userPosition.positionTrajectory',
			'userPosition.positionTrajectory.fromPosition',
			'managerRelation',
			'managerRelation.manager',
		],
	})
	const { data: roles, isLoading: isLoadingRoles } = useGetUserOrganizationRolesV2(restClient, employeeId)

	const isUserLoading = isLoading || isLoadingRoles
	const isAuthUserEmployeeManager = me.id === employee?.managerRelation?.managerId

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Employee" />
			{isUserLoading && <Loader />}
			{!isUserLoading && (
				<>
					<div className="grid grid-cols-12 gap-8">
						<div className="rounded-xl bg-white shadow-default dark:border-strokedark dark:bg-boxdark col-span-12 p-4 flex flex-col items-center">
							{employee && (
								<>
									<div className="relative z-30 mx-auto h-20 w-full max-w-20 bg-white/20 overflow-hidden flex justify-center items-center rounded-full mt-7">
										{employee.avatar && <img src={employee.avatar} alt="profile_picture" />}
										{!employee.avatar && (
											<img
												src="https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg"
												alt="profile_picture"
											/>
										)}
									</div>
									<h3 className="text-2xl mb-2 mt-4 font-semibold text-black">{employee.name}</h3>
									<p className="text-xl mb-2">{employee.userPosition?.positionTrajectory?.fromPosition?.title}</p>
									<p className="text-l mb-2">
										{employee.userPosition?.positionTrajectory?.fromPosition?.role}{' '}
										{employee.userPosition?.positionTrajectory?.fromPosition?.level}
									</p>
									<p className="text-l mb-2">{employee.organization?.name}</p>
									<div className="flex justify-center items-center gap-2">
										{isAuthUserAdmin && (
											<button
												onClick={() => openEditRoles({ employeeId })}
												className="inline-flex items-center justify-center rounded-md bg-meta-6 px-3 py-1 text-center font-medium text-white hover:bg-opacity-90"
											>
												Edit user roles
											</button>
										)}
										{(isAuthUserEmployeeManager || isAuthUserAdmin) && (
											<button
												onClick={() => openChangeManager({ employeeId, organizationId: employee.organization!.id })}
												className="inline-flex items-center justify-center rounded-md bg-meta-6 px-3 py-1 text-center font-medium text-white hover:bg-opacity-90"
											>
												Change manager
											</button>
										)}
										{isAuthUserEmployeeManager && (
											<button
												onClick={() => openPromote({ employeeId })}
												className="inline-flex items-center justify-center rounded-md bg-meta-3 px-3 py-1 text-center font-medium text-white hover:bg-opacity-90"
											>
												Promote
											</button>
										)}
									</div>
								</>
							)}
						</div>
					</div>
					<div className="flex flex-col gap-10 mt-4">
						{employee && roles && (
							<TabbedTableView
								employeeId={employeeId}
								employeeManagerId={employee.managerRelation?.managerId}
								roles={roles}
								employee={employee}
							/>
						)}
					</div>
				</>
			)}
		</DefaultLayout>
	)
}

export default EmployeeDetail
