import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IRole } from './interfaces.ts'

export const getOrganizationRoles = async (client: HttpClient) => {
	const response = await client.get<IRole[]>(`/api/organization-roles`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
