import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { mapData, SurveyJson } from '../Surveys/Surveys.tsx'
import 'survey-core/defaultV2.min.css'
import { ITheme, Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import Loader from '../../common/Loader'
import { getMe } from '../../MeProvider.ts'
import { getAssessmentResponseByUserId } from './utils.ts'
import CustomTheme from './../../surveyCustomTheme.json'
import { useRestClient } from '../../useRestClient.ts'
import { useCompleteAssessmentResponse, useGetAssessment, useSaveAnswers } from '../../http/hooks.ts'

interface AssessmentSurveyProps {
	assessmentId: string
	responseId: string
	data: SurveyJson
}

const SurveyLayer = ({ data, responseId, assessmentId }: AssessmentSurveyProps) => {
	const queryClient = useQueryClient()
	const restClient = useRestClient()
	const mutation = useSaveAnswers(restClient)
	const completeMutation = useCompleteAssessmentResponse(restClient)
	const navigation = useNavigate()
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

	const survey = new Model(data)
	const surveyComplete = useCallback(async (sender: any) => {
		try {
			setIsSubmitting(true)
			const payload = {
				answers: Object.entries(sender.data).map(([questionId, answer]) => ({
					questionId: questionId,
					answerValue: answer as number,
				})),
			}
			await mutation.mutateAsync({ assessmentResponseId: responseId, answers: payload })
			await completeMutation.mutateAsync(responseId)
			await queryClient.invalidateQueries({ queryKey: ['UserAssessments'] })
			navigation(`/assessments/${assessmentId}`)
		} catch (e) {
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}, [])

	survey.onComplete.add(surveyComplete)
	survey.applyTheme(CustomTheme as unknown as ITheme)

	if (isSubmitting) {
		return <Loader />
	}

	return <Survey model={survey} />
}

const AssessmentSurvey = () => {
	const { assessmentId, responseId } = useParams()
	const restClient = useRestClient()

	if (!assessmentId || !responseId || assessmentId === 'undefined' || responseId === 'undefined') {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	const { id } = getMe()
	const { data } = useGetAssessment(restClient, {
		assessmentId,
		includes: ['assessmentType', 'assessmentType.questions', 'assessmentResponses', 'employee'],
	})

	if (!data) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	const myResponse = getAssessmentResponseByUserId(data, id!)

	if (!myResponse) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>Not allowed to be here</div>
				</div>
			</DefaultLayout>
		)
	}

	if (myResponse.completedAt) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>You have already answered this assessment</div>
				</div>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Assessment" />
			<div className="flex flex-col gap-10">
				<SurveyLayer data={mapData(data.assessmentType!.questions!, data.employee!)} assessmentId={assessmentId} responseId={responseId!} />
			</div>
		</DefaultLayout>
	)
}

export default AssessmentSurvey
